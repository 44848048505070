import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/nb';

import Arrow from 'images/icons/ArrowRight';

dayjs.locale('nb');
dayjs.extend(customParseFormat);

const CalendarContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1px;
`;

const Head = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 10px;
	margin-bottom: ${p => p.theme.spacing.desktop.small};
	${p =>
		p.theme.media.mediumOnly(css`
			margin-bottom: ${p => p.theme.spacing.tablet.small};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			margin-bottom: ${p => p.theme.spacing.mobile.xsmall};
		`)}
`;

const NavButton = styled.button`
	padding: 3.57px 14.5px;
	background: transparent;
	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

const ArrowIcon = styled(Arrow)`
	transform: ${p => (p.rotate === 'true' ? 'rotate(180deg)' : 'none')};
	width: 9px;
	height: 29px;
	color: ${p => p.theme.colors.black};
`;

const MonthDisplay = styled.div`
	font-size: 17px;
	line-height: 29px;
	font-weight: 500;
`;

const WeekDays = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 0;
	width: 100%;
	margin-bottom: 5px;
`;

const WeekdayLabel = styled.abbr`
	text-align: center;
	text-decoration: none;
`;

const DaysGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 0;
	width: 100%;
`;

const ButtonDay = styled.button`
	text-align: center;
	font-weight: 400;
	font-size: 17px;
	height: 39px;
	padding: 5px 0;
	background: transparent;
	border: 1px solid ${p => p.theme.colors.grey400};
	margin-bottom: -1px;
	margin-right: -1px;
	&:disabled {
		cursor: not-allowed;
		pointer-events: none;
	}
	&:hover:not(:disabled) {
		border: 1px solid ${p => p.theme.colors.grey700};
		z-index: 1;
	}
	&:nth-child(1) {
		border-radius: 5px 0 0 0;
	}
	&:last-child {
		border-radius: 0 0 5px 0;
	}
	&:nth-child(7) {
		border-radius: 0 5px 0 0;
	}
	&:nth-child(29) {
		border-radius: 0 0 0 5px;
	}

	${p =>
		p.active === 'true' &&
		css`
			outline: 2px solid ${p => p.theme.colors.blue600};
			outline-offset: -1px;
			color: ${p => p.theme.colors.blue600};
			z-index: 1;
			&:hover {
				outline: 2px solid ${p => p.theme.colors.blue600};
			}
		`}

	&:focus-visible, &:focus {
		outline: 2px solid ${p => p.theme.colors.blue600};
		outline-offset: -1px;
		z-index: 1;
	}
`;

/**
 * Calendar component, displays a calendar with the ability to navigate between months
 * @param {number} numberOfMonths - Number of months to display
 * @param {boolean} disableWeekends - Disable weekends
 * @param {Array} enabledDates - Array of timestamps for enabled dates, unix format
 * @param {dayjs} activeDate - The currently active date
 * @param {function} setActiveDate - Function to set the active date
 */
export default function Calendar({
	numberOfMonths = 3,
	disableWeekends = false,
	enabledDates = [],
	activeDate,
	setActiveDate,
}) {
	/**
	 * Filter out invalid timestamps, if any
	 * @returns {Array}
	 */
	const validEnabledDates = useMemo(() => {
		return enabledDates?.filter(isValidUnixTimestamp);
	}, [enabledDates]);

	/**
	 * Create a set of enabled dates to check if a date is enabled
	 */
	const enabledDatesSet = useMemo(() => {
		return new Set(
			validEnabledDates?.map(timestamp =>
				dayjs.unix(timestamp).format('YYYY-MM-DD')
			)
		);
	}, [validEnabledDates]);

	/**
	 * Sort the enabled dates in ascending order, to get the earliest date first
	 */
	const sortedEnabledDates = useMemo(
		() =>
			enabledDates?.length > 0
				? [...enabledDates].sort((a, b) => a - b)
				: [],
		[enabledDates]
	);

	/**
	 * Set currentMonth to the earliest enabled date or today's
	 */
	const [currentMonth, setCurrentMonth] = useState(() => {
		if (!sortedEnabledDates?.length > 0) return dayjs().startOf('month');

		const earliestDate = dayjs.unix(sortedEnabledDates[0]);
		return earliestDate.startOf('month');
	});

	// Add months to a date
	const addMonths = (date, months) => {
		return date.add(months, 'month');
	};

	/**
	 * Check if navigation is disabled, based on the current month and the number of months to add
	 * @param {number} monthsToAdd
	 * @returns {boolean}
	 */
	const isNavigationDisabled = monthsToAdd => {
		const today = dayjs().startOf('month');
		const maxDate = addMonths(dayjs(), numberOfMonths - 1).startOf('month');
		const newDate = addMonths(dayjs(currentMonth), monthsToAdd).startOf(
			'month'
		);
		// Disable navigation if trying to navigate past the max or before today
		if (newDate.isAfter(maxDate) || newDate.isBefore(today)) {
			return true;
		}

		// Additional check for disabling the previous button
		if (monthsToAdd < 0 && enabledDates.length > 0) {
			const earliestDate = dayjs.unix(enabledDates[0]).startOf('month');
			// Check if there are any enabled dates in the new month after navigation
			const hasEnabledDatesInNewMonth = validEnabledDates.some(date =>
				dayjs.unix(date).startOf('month').isSame(newDate, 'month')
			);
			// If the earliest enabled date is not in the previous month or there are no enabled dates in the new month, disable the button
			if (
				!earliestDate.isSame(newDate, 'month') &&
				!hasEnabledDatesInNewMonth
			) {
				return true;
			}
		}
		return false;
	};

	// Navigate to a month
	const navigateMonth = monthsToAdd => {
		setCurrentMonth(addMonths(dayjs(currentMonth), monthsToAdd));
	};

	// List of weekdays
	const weekDays = [
		'Mandag',
		'Tirsdag',
		'Onsdag',
		'Torsdag',
		'Fredag',
		'Lørdag',
		'Søndag',
	];

	return (
		<CalendarContainer>
			<Head>
				<NavButton
					onClick={() => navigateMonth(-1)}
					disabled={isNavigationDisabled(-1)}
					aria-label={`Forrige måned (${dayjs(currentMonth)
						.subtract(1, 'month')
						.format('MMMM YYYY')})`}>
					<ArrowIcon rotate="true" />
				</NavButton>
				<MonthDisplay
					dateTime={currentMonth.format('YYYY-MM')}
					aria-live="polite"
					id="calendar-month-year">
					{dayjs(currentMonth).format('MMMM YYYY')}
				</MonthDisplay>
				<NavButton
					onClick={() => navigateMonth(1)}
					disabled={isNavigationDisabled(1)}
					aria-label={`Neste måned (${dayjs(currentMonth)
						.add(1, 'month')
						.format('MMMM YYYY')})`}>
					<ArrowIcon />
				</NavButton>
			</Head>
			<WeekDays>
				{weekDays.map((dayLabel, index) => (
					<WeekdayLabel key={index} title={dayLabel}>
						{dayLabel?.charAt(0)}
					</WeekdayLabel>
				))}
			</WeekDays>
			<DaysGrid
				role="grid"
				aria-labelledby="calendar-month-year"
				data-cy="calendar-grid">
				{generateMonthDays(currentMonth).map((day, i) => (
					<ButtonDay
						key={i}
						dateTime={day.date.format('YYYY-MM-DD')}
						data-cy="calendar-grid-date"
						title={`Velg denne dagen: ${day.date.format(
							'D. MMMM YYYY'
						)}`}
						disabled={
							!day.isCurrentMonth ||
							(enabledDatesSet.size > 0 &&
								!enabledDatesSet.has(
									day.date.format('YYYY-MM-DD')
								))
								? true
								: disableWeekends && day?.isWeekend
								? true
								: false
						}
						onClick={() => {
							if (setActiveDate) setActiveDate(day?.date);
						}}
						active={
							activeDate && day?.date.isSame(activeDate, 'day')
								? 'true'
								: 'false'
						}
						hide={!day.isCurrentMonth ? 'true' : 'false'}>
						{day?.isCurrentMonth ? dayjs(day?.date).date() : ''}
					</ButtonDay>
				))}
			</DaysGrid>
		</CalendarContainer>
	);
}

/**
 * Generate the days of the month based on the current month
 * @param {dayjs} currentMonth
 * @returns {Array}
 */
function generateMonthDays(currentMonth) {
	if (!currentMonth) return [];

	const days = [];

	// Get the first and last day of the month and the current month as 'dayjs
	const firstDayOfMonth = currentMonth.startOf('month');
	const lastDayOfMonth = currentMonth.endOf('month');

	// Get the day of the week for the first and last day of the month
	let firstDayOfWeek = firstDayOfMonth.day();
	firstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

	let lastDayOfWeek = lastDayOfMonth.day();
	lastDayOfWeek = lastDayOfWeek === 0 ? 6 : lastDayOfWeek - 1;

	/**
	 * Loop through the first days of the week and add them to the days array at the beginning
	 */
	for (let i = 0; i < firstDayOfWeek; i++) {
		days.unshift({
			date: firstDayOfMonth.subtract(i + 1, 'day'),
			isCurrentMonth: false,
		});
	}

	/**
	 * Loop through the days of the month and add them to the days array
	 */
	for (let i = 0; i <= lastDayOfMonth.date() - firstDayOfMonth.date(); i++) {
		days.push({
			date: firstDayOfMonth.add(i, 'day'),
			isCurrentMonth: true,
		});
	}

	/**
	 * Loop through the last days of the week and add them to the days array at the end
	 */
	for (let i = 1; i < 7 - lastDayOfWeek; i++) {
		days.push({
			date: lastDayOfMonth.add(i, 'day'),
			isCurrentMonth: false,
		});
	}

	/**
	 * Return the days array with the isWeekend property
	 */
	return days.map(day => ({
		...day,
		isWeekend: day.date.day() === 0 || day.date.day() === 6,
	}));
}

/**
 * Check if a value is a valid UNIX timestamp
 * @param {number} value - The value to check
 * @returns {boolean}
 */
function isValidUnixTimestamp(value) {
	if (!value) return false;
	const date = dayjs.unix(value);
	return date.isValid();
}
